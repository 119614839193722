.workloadcont {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  border-radius: 10px;
  padding: 20px;
}

/* .select-wrapper {
    position: relative;
    display: inline-block;
  } */

.esg_text2 {
  /* appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; */
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #000000;
  margin-bottom: 5px;
  border: 2px solid #3f88a5 !important;
  border-radius: 10px;
  padding: 10px;
}

.m-n20 {
  color: "#011627";
  margin-top: 2px;
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 600;
}

.esg_text2::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

/* 

  .esg_text2 option {
    background-color: #fff;
    color: #333;
    padding: 10px;
  }

  .esg_text2 option:hover {
    background-color: #f1f1f1;
  }

  .esg_text2:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  } */

  .pagination-arrows svg {
    width: 24px;
    height: 24px;
    fill: #000; /* Adjust color as needed */
  }
  
  .pagination-arrows:hover {
    opacity: 0.7;
  }
  
  .pagination-arrows:active {
    opacity: 0.5;
  }