/* customScrollbar.css */

/* Custom scrollbar styling for WebKit browsers */
.scroll-container::-webkit-scrollbar {
  width: 12px;
}

.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.scroll-container::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Custom scrollbar styling for Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: #3f88a5 #f1f1f1;
}
