.water-withdrawn-container {
    width: 100%;
    padding: 20px;
    height: 100%;
  
    border-radius: 10px;
    background-color: white;
  }
  
  .water-withdrawn-header {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .water-withdrawn-bar-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
  }
  
  .water-withdrawn-bar-dotted-line {
    width: 100%;
    border-top: 1px dotted #000;
    margin-bottom: 5px;
  }
  
  .water-withdrawn-bar {
    display: flex;
    width: 100%;
    height: 30px; /* Adjust height as needed */
    border: 1px solid #000;
    position: relative;
  }
  
  .water-withdrawn-bar-filled {
    height: 100%;
    background-color: green;
  }
  
  .water-withdrawn-bar-not-generated {
    height: 100%;
    background-color: orange;
  }
  
  .water-withdrawn-bar-remaining {
    height: 100%;
    background-color: lightgray;
  }
  