.white:hover {
    color: #fff !important;
  }
  .big-button{
    display: flex;
    justify-content: center;
  }
  
  .nextbtn {
    pointer-events: none;
  }
  
  /* .pb3 h4 {
    font-size: 30px;
    font-weight: 700;
    color: #1f9ed1;
    margin: 0;
  } */
  input#formBasicEmail {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pb3 .dropdown {
    display: flex;
    flex-direction: column;
  }
  .pb3 .dropdown input#dropdownMenuLink {
    height: 52px;
    background: #f1f5fb;
  }
  .pb3 .dropdown-menu.border-0.shadow.show {
    width: 100%;
  }
  .pb3 .dropdown .shadow ul {
    height: 213px;
  }
  .pb4 .input-group input.form-control {
    padding-left: 45px;
    height: 52px;
    margin-bottom: 20px;
  }
  .pb4 .input-group {
    position: relative;
  }
  .pb4 .input-group .form-control-feedback.search-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    color: #abb1c8;
  }
  .pb4 .input-group .dropdown-menu.show {
    width: 100%;
    margin-top: 15px;
  }
  .pb4 .dropdown-item.form.d-flex.justify-content-between {
    margin: 0;
    border-bottom: 1px solid #E3EBED;
    padding: 20px 20px;
  }
  .pb4 .form-check-inline label.form-check-label {
    font-size: 24px;
  }
  .pb4 .form-check-inline input#inlineRadio1 {
    margin-top: 8px;
  }
  .supp4 label {
    font-weight: 700;
    color: #333333;
  }
  
  .form-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
  }
  .form-head .form-group {
    width: 100%;
    display: flex;
  }
  .form-head .form-group .form-control {
    height: 51px;
    margin: 0 20px;
  }
  .form-head .form-group span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add_text a {
    font-size: 14px;
  }
  .question_form {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .color-blue {
    color: #2b377b;
  }
  
  .pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a {
    background-color: #47ccde;
    border-color: #47ccde;
    color: #fff;
  }
  .pagination > li > a {
    border: 1px solid #47ccde;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    background-color: #47ccde;
    border-color: #47ccde;
    outline: none;
  }
  .pagination > li > a,
  .pagination > li > span {
    color: #47ccde;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span,
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-radius: unset;
  }
  .pagination {
    display: inline-block;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }
  
  a.outputFile {
    border: 1px solid #05b49d;
    border-radius: 5px;
    padding: 15px 40px;
    text-decoration: none;
    margin-left: 20px;
    font-size: 15px;
    color: #038977;
  }
  
  a.outputFile:hover {
    background-color: #05b49d1a;
    border: 1px solid #05b49d;
    border-radius: 5px;
    padding: 15px 40px;
    text-decoration: none;
    margin-left: 20px;
    font-size: 15px;
    color: #038977;
  }