/* Dropdown Container */
.dropdown-container {
    position: relative;
    width: 274px;
    height: 47px;
    border: 1px solid #b3c2cc; /* Border color from the image */
    border-radius: 5px;
    overflow: hidden;
    background-color: #f5f9fc; /* Background color */
    display: flex;
    align-items: center;
  }
  
  /* Custom Dropdown */
  .custom-dropdown {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 30px 0 10px; /* Padding to accommodate the icon */
    font-size: 16px;
    background: white;
    appearance: none; /* Remove default arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
  }
  
  /* Dropdown Icon */
  .dropdown-icon {
    position: absolute;
    right: 10px; /* Adjust as needed */
    width: 20px; /* Icon size */
    height: 20px;
    background: url('../../../../../img/DownArrow.svg') no-repeat center; /* Use an actual path to your icon */
    pointer-events: none; /* Prevent icon from blocking dropdown interaction */
  }
  
  /* Additional Styling for Option Text */
  .custom-dropdown option {
    padding: 5px;
    font-size: 16px;
  }
  