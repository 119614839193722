.action__button__section{
    width:100%;
    display: flex;
    /* align-items: flex-end; */
    /* justify-content: flex-end; */
    column-gap: 20px;
    /* margin-top: 20px; */
}

.accept__button{
    background-color: #fff;
    border: 1px solid #0095d1;
    padding: 10px 30px;
    border-radius: 30px;
    color:#0095d1;
    cursor: pointer;
}

.reject__button{
  background-color: #fff;
  border: 1px solid #e40513;
  padding: 10px 30px;
  border-radius: 30px;
  color: #e40513;
  cursor: pointer;
}

.remarks__popup__wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.remarks__popup__wrapper h1{
    font-size: 16px;
    text-align: left;
    font-weight: 700;
}

.remarks__popup__wrapper textarea{
  font-size: 16px;
  border: 1px solid #898989;
  border-radius: 7px;
  padding: 11px;
}

/* .remarks__popup__wrapper button{
    background-color: #0095d1;
    border: none;
    padding: 10px 30px;
    border-radius: 30px;
    color:#fff;
    cursor: pointer;
    font-size: 16px;
} */
.question_type_filter {
    border-bottom: 0px !important;
    display: flex;
    gap: 40px;
    /* margin: 0 25px 10px; */
  }
  
  .question_type_filter  a{
    cursor: pointer;
    padding: 10px 0px;
    font-weight: 600;
    text-decoration: none;
    color:#4f5367;
  }
  
  /* .selected_question_type {
    border-bottom: 3px solid #1f9ed1;
    color: #1f9ed1 !important;
  } */
