.container {
    padding: 20px;
    background:white;
    border-radius: 15px;
    height: 100%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    height: 10%;
    margin-left: 2%;
    margin-bottom: 2%;
  }
  
  .title {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
  }
  
 
  
  .chart-container {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    height: 85%;
    width: 100%;
  }
  
  .chart-inner-container {
    width: 100%;
    height: 100%; /* Adjust width based on the number of bars */
  }
  
  .legend-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .legend-color-box {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
  