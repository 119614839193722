.receive__message__wrapper{
    display: flex;
    justify-content: flex-start;
    background-color: #1f9ed1;
    padding:10px;
    font-size: 14px;
    width:fit-content;
    border-radius: 10px;
    color:#fff;
    margin-bottom: 10px;
    margin-right: 40px;
}

.send__message__wrapper{
    display: flex;
    justify-content: right;
    align-items: flex-end;
    margin-bottom: 10px;

}
.send__message__wrapper .heading{
    font-size: 16px;
    border-bottom: 1px solid #c7c8c8;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.send__message__wrapper .messageWrapper{
    background-color: #6f7273;
    width:fit-content;
    border-radius: 10px;
    padding:10px;
    font-size: 14px;
    color: #fff;
    margin-left: 40px;
}

.message__section{
    max-height: 300px;
    overflow-y: scroll;
}

