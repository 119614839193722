/* .home_drop {
  margin-top: 20px;
} */

/* .dropdown_menu a i {
  color: #fff;
  background: #1f9ed1;
  padding: 8px 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  text-align: center;
} */

.dropdown_menu span {
  margin: 0 10px;
  color:#3F88A5;
}

.d-flex .background2 {
  background: #ffffff;
  padding-bottom: inherit;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 1045;
}

.back_text{
  color:#3F88A5;
  font-size: 28;
font-family: 'Open Sans',sans-serif;
font-weight: 700;
word-wrap:"break-word"
}


.back_quninti:hover, .back_quninti a:hover, .back_quninti .step_icon:hover, .back_quninti i:hover {
  cursor: pointer;
}

li.nav-item.dropdown.text_down {
  /* margin-bottom: 0px; */
  /* display: flex; */
  align-items: center;
  /* grid-gap: 20px; */
  /* grid-gap: 11px; */
  gap: 11px;
}

/* li.nav-item.activities-b {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
}

.activation-notification {
  position: relative;
}
.activation-notification span.badge {
  position: absolute;
  top: -10px;
  z-index: 3;
  background: #0d6efd;
  right: -8px;
  border-radius: 50%;
}
.activation-notification a{
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(25, 97, 156, 0.2);
  padding: 15px;
  color: #0d6efd;
} */

/* .toggle__btn__styling{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 47%;
} */

.toggle__label {
  display: flex;
}

.activation-notification {
  position: relative;
}

.activation-notification span.badge {
  position: absolute;
  top: -10px;
  z-index: 3;
  background: #1f9ed1;
  right: -8px;
  border-radius: 50% !important;
}
.activation-notification span.badge::before {
  content: none;
}

.activation-notification span.badge::after {
  content: none;
}

.activation-notification a {
  background: #fff;
  position: relative;
  color: #1f9ed1;
  border: 1px solid;
  border-radius: 4px;
  padding: 5px 16px;
}

.activation-notification a i {
  position: absolute;
  top: 9px;
  left: 9px;
}

.notification_section h5 {
  font-size: 15px;
  padding: 5px 10px;
  margin: 0;
  font-weight: bold;
}

.notification_section a {
  font-size: 12px;
  /* padding: 5px 15px; */
  margin: 0;
}

.notification_section i {
  font-size: 12px;
  padding: 5px 15px;
  margin: 0;
  transform: rotate(315deg);
}

.notification_body {
  padding: 15px 15px 0;
  height: 450px;
  overflow: auto;
}

.notification_body i {
  font-size: 25px;
  padding: 10px 0;
  transform: rotate(0deg) !important;
}

.notification_body h4 {
  font-size: 16px;
  margin: 0;
  font-weight: bold;
}

.notification_body p {
  font-size: 12px;
  margin: 0;
}
