.audit-tabs-container {
    background-color: #e0ebeb; /* Background color */
    padding: 20px;
    border-radius: 10px;
  }
  
  .tabs-audit {
    display: flex;
    gap: 3%;
    margin-bottom: 5%;
  }
  
  .tabs-audit button {
    background-color: transparent;
    border: none;
    padding: 6px 16px;
    border-radius: 5px;
    border: 2px solid #3f88a5;
    color: black;
    font-weight: normal;
    cursor: pointer;
  }
  
  .tabs-audit button.active {
    background-color: #3f88a5;
    color: white;
  }
  
  .table-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;

  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .audit-td {
    padding: 5px;
    text-align: left;
    border-bottom: 2px solid #83BBD5


    ;
  }
  
  .th{

    padding: 5px;
    text-align: left;
    border-bottom: 2px solid #83BBD5
  }

  th {
    background-color: white;
    color: #11546F
    ;
    font-size: small;
  }
  
  tr:nth-child(odd) {
    background-color: white;
    color: #3F88A5;
    font-size: small;
  }

  tr:nth-child(even) {
    background-color: white;
    color: #3F88A5;
    font-size: small;
  }
  