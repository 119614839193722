.framework-selector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allows frameworks to wrap */
  /* align-items: center; */
  /* justify-content: space-between; Space between frameworks and button */
  height: 10%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;

  margin: 0 auto; /* Center the component */
  box-sizing: border-box;
}

.framework-options {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap */
  gap: 10px;
  padding:10px;
  flex-grow: 1; /* Allows it to grow and take up available space */
  margin-right: 10px;
  width:50% /* Space between options and button */
}

.button-class{
  width: 30%;
  justify-content: flex-end;
  margin-right: 10px;
  display: flex;

}

.framework-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #3f88a5;
  font-size: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  width: calc(25% - 20px); /* Four items per row with gap */
  box-sizing: border-box; /* Include padding and border in width */
}

.framework-label {
  color: #333; /* Darker color for better readability */
  font-size: 11px!important;
  font-weight: 450;
  margin-right: 10px; /* Space between label and button */
}

.remove-button {
  border: none;
  background-color: transparent;
  color: #3f88a5;
  font-size: 20px;
  cursor: pointer;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.add-framework-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #3f88a5;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  align-self: flex-start; /* Keeps button at top-right */
}

.add-framework-button:hover {
  background-color: #2c6781; /* Darker shade for hover */
}
 