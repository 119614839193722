.spline-area-chart-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.spline-area-chart-title {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #011627;
    text-align: left;
}
