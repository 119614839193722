.renewable-bar-container {
    width: 100%;
    padding: 20px;
    height: 100%;
  
    border-radius: 10px;
    background-color: white;
  }
  
  .renewable-bar-header {
  
    font-size: 16px;
    font-weight: bold;
  }
  
  .renewable-bar-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
  }
  
  .renewable-bar-dotted-line {
    width: 100%;
    border-top: 1px dotted #000;
    margin-bottom: 5px;
  }

  .renewable-bar-legends {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-right: 20px;
  }
  
  .legend-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .legend-value {
    margin-left: 8px;
    font-size: 11px;
  }
  
  
  .renewable-bar {
    display: flex;
    width: 100%;
    height: 30px; /* Adjust height as needed */
    border: 1px solid #000;
    position: relative;
  }
  
  .renewable-bar-filled {
    height: 100%;
    background-color: green;
  }
  
  .renewable-bar-not-generated {
    height: 100%;
    background-color: orange;
  }
  
  .renewable-bar-remaining {
    height: 100%;
    background-color: lightgray;
  }
  