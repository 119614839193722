.home_icon_img li a svg {
  position: relative;
}

.home_icon_img li a {
  border: none;
  display: flex;
  align-items: center;
  height:30px;
  background: #fff;
}

.home_icon_img .list-group-item-light.list-group-item-action.active {
  color: white !important;
  background: var(--primaryColor) !important;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 4px 10px rgba(25, 97, 156, 0);
  height: 35px;
  width: 100%;
}

.overView{
  width: 100%;
}

