.omne {
    width: 100%;
}
.impa {
    justify-content: center !important;
}

.re-submit1 {
    color: #1f9ed1 !important;
    background: #f6f7fa;
    border: none;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 16px !important;
    letter-spacing: 2px;
}