

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  backdrop-filter: blur(10px); /* Adjust the blur strength as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: max-content;
  width: 100%;
}
.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-left: 2px;
  
}

.custom-checkbox {
  margin-right: 10px;
  border: 2px solid #3F88A5 !important;
  width: 16px;
  height: 16px;
}

.checkbox-label span {
  flex-grow: 1;
  white-space: nowrap; /* Prevents wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis for overflowing text */
}

.checkbox-label {
  width: 100%; /* Ensures full width of the container */

}

   
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    height:max-content;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .modal-header h5 {
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-body {
    padding: 10px 0;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .btn-primary {
    background-color: #3F88A5;
    color: white;
    font-size: 14px;
    padding: 13px 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  /* SelectWithFilter.css */
 .select-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .form-control {
    width: 100%;
    padding-right: 2.5rem; 
    padding-left: 1rem; 
  }
  
  .filter-iconn {
    position: absolute;
    top: 50%;
    right: 0.5rem; 
    transform: translateY(-50%);
  
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  

.select-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .select-container select {
    padding-right: 30px; 
    width: 100%; 
  }
  
  .select-container .dropdown-icon {
    position: absolute;
    right: 10px; 
    font-size: 16px;
    color: #aaa;
    pointer-events: none; 
  }

.time-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .time-input-container input[type="time"] {
    padding-right: 30px; 
    width: 100%; 
  }
  
  .time-input-container .dropdown-icon {
    position: absolute;
    right: 10px; 
    font-size: 16px;
    color: #aaa; 
    pointer-events: none; 
  } 
    
