.energy-bar-container {
    width: 100%;
    padding: 20px;
    height: 100%;
  
    border-radius: 10px;
    background-color: white;

  }


.energy-bar-dotted-line {
    border-top: 1px dotted #000; /* Dotted line */
    margin-bottom: 2%; /* Space between line and bar */
  }
  
  .energy-bar-header {
    font-family: Arial, sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #011627;

  }
  
  .energy-bar {
    width: 100%;
    height: 25px;
    background-color: #e0e0e0;
    border-radius: 5px;
    position: relative;
  }
  
  .energy-bar-filled {
    height: 100%;
    background-color: #2a6478; /* Dark blue color */
    border-radius: 5px;
  }
  
  .energy-bar-labels {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 5px;
    font-family: Arial, sans-serif;
    color: #000;
  }
  