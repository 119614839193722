.container {
    padding: 20px;
    background:white;
    border-radius: 15px;
    height: 100%;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: left;
    height: 10%;
    margin-left: 2%;
    margin-bottom: 2%;
  }

  .chartContainer{
    height: 75%;
    width: 100%;
  }
  
  .title {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
  }
  
  .legend-container {
    display: flex;
    align-items: left;
    justify-content: flex-start;
    margin-left: 5%;

    height: 10%;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 5%;
  }
  
  .legend-color-box {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border-radius: 50px;
  }
  