/* Parent container with gap */
.gap-container {
    gap: 8px; /* Adjust the gap as needed */
  }
  
  /* Button styles */
  .btn-custom-selected {
    background-color: #3F88A5;
    color: white;
    border: 1px solid #3F88A5;
    width: calc(50% - 4px); /* Adjusting width to account for the gap */
    transition: background-color 0.3s, color 0.3s;
  }
  
  .btn-custom {
    background-color: transparent;
    color: black;
    border: 1px solid #3F88A5;
    width: calc(50% - 4px); /* Adjusting width to account for the gap */
    transition: background-color 0.3s, color 0.3s;
  }
  
  .btn-custom:hover {
    background-color: #3F88A5;
    color: white;
  }
  
  /* Custom input field styles */
.custom-input {
    background-color: transparent; /* Transparent background */
    border: 1px solid #3F88A5; /* Same border color */
    color: #000; /* Text color */
    padding: 6px; /* Add some padding */
    border-radius: 4px; /* Optional rounded corners */
    outline: none; /* Remove default outline on focus */
    transition: border-color 0.3s, background-color 0.3s, color 0.3s; /* Smooth transitions */
  }
  
  /* Placeholder text in grey */
  .custom-input::placeholder {
    color: #888; /* Grey color for placeholder text */
  }
  
  /* Add focus styles */
  .custom-input:focus {
    border-color: #3F88A5; /* Same border color on focus */
    background-color: transparent; /* Ensure background stays transparent */
    color: #000; /* Ensure text color is black */
    outline: none; /* Remove default focus outline */
  }
  
  /* Additional styles to remove Bootstrap's box shadow if necessary */
  .custom-input:focus-visible {
    box-shadow: none; /* Remove Bootstrap focus box-shadow */
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 16px; /* Gap between grid items */
    /* padding: 20px; */
  }
  
  /* Grid item styling */
  .grid-item {
    display: flex;
    align-items: center;
    border: 1px solid #3F88A5; /* Border color */
    padding: 12px;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  
  /* Checkbox styling */
  .grid-item input[type="checkbox"] {
    appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #007b8f;
  border-radius: 3px;
  width: 25px;
  height: 25px;
  display: inline-block;
  position: relative;
  margin-right: 10px;

  }
  
  .grid-item input[type="checkbox"]:checked::before {
    content: "✔";
    font-size: 13px;
    color: #007b8f;
    position: absolute;
    top: 1px;
    left: 2px;
  }
  /* Topic label styling */
  .topic-label {
    flex-grow: 1; /* Ensure the label takes up available space */
    color: #000;
    font-weight: 500;
  }
  .filter-icon{
    border: 1px solid #3F88A5;
    padding: 5px;
    border-radius: 4px;
  }
  .filter-icon img{
    width: 100%;
    height: 20px;
  }

  /* checkbox-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    padding: 20px;
    
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #007bff;
    font-weight: bold;
    padding: 10px 10px;
  }
  
  .checkbox-input {
    display: none;
  }
  
  .checkbox-custom {
    width: 20px;
    height: 20px;
    border: 2px solid #007bff;
    border-radius: 3px;
    margin-right: 10px;
    position: relative;
  }
  
  .checkbox-input:checked + .checkbox-custom::after {
    content: '\2713';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #007bff;
    font-size: 16px;
  }
  
  .checkbox-text {
    font-size: 14px;
  } */