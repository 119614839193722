.parent-enviornment .enviornment {
  display: block;
}

.page_width {
  width: auto !important;
  text-decoration: none;
  height: 50px;
}

.page_width_mini {
  width: auto !important;
  padding: 10px !important;
}

@media (min-width: 768px) and (max-width: 1401px) {
}

.gement2 {
  width: 100%;
  max-width: 200px;
}

.manag .form-floating textarea#floatingTextarea {
  padding-top: 34px;
}

.manag .form-floating textarea#floatingTextarea {
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;
}

.manag .form-floating textarea#floatingTextarea::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

.page_save_green {
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  margin-right: 30px;
  color: #05b49d;
  background: #fff;
}

.page_save_anchor {
  background: linear-gradient(94.47deg, #233076 1.14%, #19619c 114.41%);
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 18px !important;
  align-items: flex-end !important;
  justify-content: center;
  text-align: center !important;
  letter-spacing: 0.2em !important;
  text-transform: uppercase !important;
  color: #ffffff !important;
  margin-right: 30px !important;
  width: auto !important;
  padding: 20px !important;
}

.re-submit {
  color: #1f9ed1 !important;
  background: #fff;
  border: none;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 16px !important;
  letter-spacing: 2px;
}

.back_quninti_2 {
  display: initial !important;
  color:'#3F88A5';
}

div#sidebar-wrapper {
  position: fixed;
  overflow: scroll;
}

/* div#sidebar-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
} */

div#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.buttons button {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  background: #233076;
  padding: 20px;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.buttons button:hover {
  color: #fff !important;
}

.statement_icon span.statement_check {
  width: 25px;
  height: 25px;
}

input {
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* 
@media (min-width: 320px) and (max-width: 1440px) {
    div#sidebar-wrapper::-webkit-scrollbar {
        width: 4px;
        height: 1px;
        display: block;
    }
} */

.Quantative_Title {
  font-size: 12px;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-style {
  color: #1f9ed1 !important;
}
