.login_bt {
  padding-bottom: 30px;
}
/* .make_form .name_nf {
  background: #fff;
  border: 1px solid #E3EBED !important;
  box-sizing: border-box;
  border-radius: 6px;
} */

.button_color {
  color: #ffffff !important;
}

.ster_form .reset {
  display: flex;
  /* padding: 20px; */
  justify-content: center;
}
span.number_dex {
  font-weight: 800;
  font-size: 39px;
  /* padding-top: 40px; */
  font-style: oblique;
}
.fredo {
  display: inline-block;
  position: relative;
  font-size: 23px;
  margin: 4px 12px;
  color: #838383;
}

.global a {
  color: #1f9ed1;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}

.global {
  width: 100%;
}

.cursor {
  cursor: all-scroll;
}

span.div-divider {
  position: absolute;
  top: -14px;
  right: 46%;
  background: #fff;
  padding: 4px;
  border: 1px solid #1f9ed1;
  border-radius: 50%;
  color: #1f9ed1;
}

.g-icon {
  font-size: 20px;
  color: #1f9ed1;
  padding: 12px;
  border-radius: 50%;
  border: 1px solid;
  cursor: pointer;
}

/* Multi Step Form*/
#heading {
  text-transform: uppercase;
  color: linear-gradient(180deg, #23317e 0%, #233076 100%, #233076 100%);
  font-weight: normal;
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

.form-card {
  text-align: left;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

#msform input,
#msform textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid
    linear-gradient(180deg, #23317e 0%, #233076 100%, #233076 100%);
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  height: 38px;
  left: 350px;
  top: 835px;
  background: #1f9ed1;
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: linear-gradient(
    180deg,
    #23317e 0%,
    #233076 100%,
    #233076 100%
  );
}

#msform .action-button-previous {
  width: 100px;
  height: 38px;
  left: 350px;
  top: 835px;
  background: #1f9ed1;
  box-shadow: 0px 8px 52px rgb(64 84 164 / 36%);
  border-radius: 8px;
  border: none;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  float: right;
  text-decoration: none;
  margin-right: 10px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background: linear-gradient(
    90deg,
    #1f9ed1 0%,
    rgb(0 149 209 / 46%) 100%
  ) !important;
}

.sign_two {
  text-align: left !important;
}

.card {
  z-index: 0;
  border: none;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: linear-gradient(180deg, #23317e 0%, #233076 100%, #233076 100%);
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.purple-text {
  color: linear-gradient(180deg, #23317e 0%, #233076 100%, #233076 100%);
  font-weight: normal;
}

.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right;
}

.fieldlabels {
  color: gray;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: linear-gradient(180deg, #23317e 0%, #233076 100%, #233076 100%);
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #account:before {
  font-family: FontAwesome;
  content: "\f13e";
}

#progressbar #personal:before {
  font-family: FontAwesome;
  content: "\f007";
}

#progressbar #payment:before {
  font-family: FontAwesome;
  content: "\f030";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "\f00c";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: linear-gradient(180deg, #23317e 0%, #233076 100%, #233076 100%);
}

.progress {
  height: 20px;
}

.progress-bar {
  background-color: linear-gradient(
    180deg,
    #23317e 0%,
    #233076 100%,
    #233076 100%
  );
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

.form-group.qrcode svg {
  width: 100%;
  height: 260px;
  max-width: 260px;
  margin: 0 auto;
}

.password-eye {
  position: relative;
}

.password-eye .img-eye {
  position: absolute;
  content: "";
  top: 40px;
  right: 20px;
  color: #1f9ed1;
}

.disabledd {
  opacity: 0.33 !important;
}
