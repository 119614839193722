.normal-text {
  color: hsl(207, 95%, 8%);
  font-size: 10px;

  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-underline-offset: 10px;

  word-wrap: break-word;
}
.heading-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    width: 100%; /* Use full viewport width */
  }
.normal-text:hover {
    cursor: pointer;
}

.normal-text.selected {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  color: #3F88A5;
  font-weight: bold;
}
